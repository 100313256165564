.steps-content {
  margin-top: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  background-color: #ffffff;
  min-height: 200px;
  //text-align: center;
  padding: 20px;
  //padding-left: 20px;
}
.steps-counter {
  margin-top: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  background-color: #ffffff;
  min-height: 150px;
  //text-align: center;
  padding: 20px;
  //padding-left: 20px;
}

.steps-action {
  margin-top: 24px;
  text-align: center;
}

.steps-container {
  padding: 2vh;
}

/* body {
  background: #d9d9d9 !important;
} */

.ant-form-item {
  margin: 0 0 0.5em 0 !important;
}
