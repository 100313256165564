.ant-DatePicker {
  margin-bottom: 20px;
}
.ant-picker-cell .ant-picker-cell-inner {
  margin-left: 11px;
  display: inline-flex !important;
  justify-content: space-between;
}
.ant-badge-status-dot {
  margin-left: 3px !important;
}
.ant-table-cell {
  width: 18em !important;
  vertical-align: middle !important;
}
.ant-space-item {
  vertical-align: middle;
}

.anticon-search {
  margin-top: 0.1em !important;
  margin-left: -1.6em !important;
}
.ant-dropdown-trigger.ant-table-filter-trigger {
  margin-top: 0.1em !important;
  margin-left: 1.6em !important;
}
.ant-menu-submenu-vertical {
  position: relative;
  // margin-top: 2em;
}
// .ant-menu-submenu.ant-menu-submenu-vertical{
// vertical-align: bottom !important;
// }
.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
  margin-top: 3em;
}
.ant-table-column-title {
  margin-top: 0.4em;
  margin-left: 2em !important;
}
