.reception-search-tag {
  .anticon-close {
    position: relative !important;
  }
}

.invetiees-limit{
  max-width: 600px;
  overflow-x: scroll;
}
