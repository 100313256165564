.entrypoints {
  button {
    float: right;
    z-index: 2;
    right: 1%;
  }
  .modal-body {
    text-align: center;
    font-weight: bold;
    font-size: large;
  }
  .ant-modal-title {
    text-align: center;
    font-weight: bold;
    font-size: large;
  }
}
